import React from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"
import { RoughNotation } from "react-rough-notation"
import Box from "./box"
import { useNotationGroup } from "./notation-group"

const Notation = (props) => {
  const { children, id, type, ...restProps } = props
  const { state, setNotationChanged } = useNotationGroup()
  const show = state?.[id]?.active

  return (
    <Box
      as={RoughNotation}
      sx={{ cursor: "pointer" }}
      onClick={() => {
        if (setNotationChanged) {
          setNotationChanged({ id, active: !show })
        }
      }}
      show={show}
      type={type}
      {...restProps}
    >
      {children}
    </Box>
  )
}

Notation.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
}

Notation.defaultProps = {
  type: "circle",
}

export default Notation
