import React from "react"
import PropTypes from "prop-types"
import Poem from "../../../../components/poem"
import Notation from "../../../../components/notation"
import PaperStack from "../../../../components/paper-stack"

const SubstantiveAdjektiveNotation = ({ size, notationType: NotationType }) => {
  return (
    <PaperStack>
      <Poem size={size}>
        <p>
          <strong>Hälfte des Lebens</strong>
        </p>
        <br />
        <p>
          <NotationType id="q-0">Mit</NotationType>{" "}
          <NotationType id="q-1">gelben</NotationType>{" "}
          <NotationType id="q-2">Birnen</NotationType>{" "}
          <NotationType id="q-3">hänget</NotationType>
        </p>
        <p>
          <NotationType id="q-4">Und</NotationType>{" "}
          <NotationType id="q-5">voll</NotationType>{" "}
          <NotationType id="q-6">mit</NotationType>{" "}
          <NotationType id="q-7">wilden</NotationType>{" "}
          <NotationType id="q-8">Rosen</NotationType>
        </p>
        <p>
          <NotationType id="q-9">Das</NotationType>{" "}
          <NotationType id="q-10">Land</NotationType>{" "}
          <NotationType id="q-11">in</NotationType>{" "}
          <NotationType id="q-12">den</NotationType>{" "}
          <NotationType id="q-13">See</NotationType>,
        </p>
        <p>
          <NotationType id="q-14">Ihr</NotationType>{" "}
          <NotationType id="q-15">holden</NotationType>{" "}
          <NotationType id="q-16">Schwäne</NotationType>,
        </p>
        <p>
          <NotationType id="q-17">Und</NotationType>{" "}
          <NotationType id="q-18">trunken</NotationType>{" "}
          <NotationType id="q-19">von</NotationType>{" "}
          <NotationType id="q-20">Küssen</NotationType>
        </p>
        <p>
          <NotationType id="q-21">Tunkt</NotationType>{" "}
          <NotationType id="q-22">ihr</NotationType>{" "}
          <NotationType id="q-23">das</NotationType>{" "}
          <NotationType id="q-24">Haupt</NotationType>
        </p>
        <p>
          <NotationType id="q-25">Ins</NotationType>{" "}
          <NotationType id="q-26">heilignüchterne</NotationType>{" "}
          <NotationType id="q-27">Wasser</NotationType>.
        </p>
        <br />
        <p>
          <NotationType id="q-28">Weh</NotationType>{" "}
          <NotationType id="q-29">mir</NotationType>,{" "}
          <NotationType id="q-30">wo</NotationType>{" "}
          <NotationType id="q-31">nehm</NotationType>’{" "}
          <NotationType id="q-32">ich</NotationType>,{" "}
          <NotationType id="q-33">wenn</NotationType>
        </p>
        <p>
          <NotationType id="q-34">Es</NotationType>{" "}
          <NotationType id="q-35">Winter</NotationType>{" "}
          <NotationType id="q-36">ist</NotationType>,{" "}
          <NotationType id="q-37">die</NotationType>{" "}
          <NotationType id="q-38">Blumen</NotationType>,{" "}
          <NotationType id="q-39">und</NotationType>{" "}
          <NotationType id="q-40">wo</NotationType>
        </p>
        <p>
          <NotationType id="q-41">Den</NotationType>{" "}
          <NotationType id="q-42">Sonnenschein</NotationType>,
        </p>
        <p>
          <NotationType id="q-43">Und</NotationType>{" "}
          <NotationType id="q-44">Schatten</NotationType>{" "}
          <NotationType id="q-45">der</NotationType>{" "}
          <NotationType id="q-46">Erde</NotationType>?
        </p>
        <p>
          <NotationType id="q-47">Die</NotationType>{" "}
          <NotationType id="q-48">Mauern</NotationType>{" "}
          <NotationType id="q-49">stehn</NotationType>
        </p>
        <p>
          <NotationType id="q-50">Sprachlos</NotationType>{" "}
          <NotationType id="q-51">und</NotationType>{" "}
          <NotationType id="q-52">kalt</NotationType>,{" "}
          <NotationType id="q-53">im</NotationType>{" "}
          <NotationType id="q-54">Winde</NotationType>
        </p>
        <p>
          <NotationType id="q-55">Klirren</NotationType>{" "}
          <NotationType id="q-56">die</NotationType>{" "}
          <NotationType id="q-57">Fahnen</NotationType>.
        </p>
      </Poem>
    </PaperStack>
  )
}

SubstantiveAdjektiveNotation.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  notationType: PropTypes.elementType,
}

SubstantiveAdjektiveNotation.defaultProps = {
  size: [3, 3, 4],
  notationType: Notation,
}

export default SubstantiveAdjektiveNotation
