import React, { useState } from "react" // eslint-disable-line no-unused-vars
import { useThemeUI } from "theme-ui"
import PropTypes from "prop-types"
import { RoughNotation } from "react-rough-notation"
import { answerStatus } from "../store/courses"
import { useNotationGroup } from "./notation-group"

const NotationSolution = (props) => {
  const { children, id, ...restProps } = props
  const { state } = useNotationGroup()
  const status = state?.[id]?.status || answerStatus.VALID_UNCHECKED

  const context = useThemeUI()
  const { theme } = context

  const solutionStyles = {
    [answerStatus.VALID_CHECKED]: {
      show: true,
      type: "circle",
      color: theme.colors["positive"],
    },
    [answerStatus.VALID_UNCHECKED]: {
      show: false,
    },
    [answerStatus.INVALID]: {
      show: true,
      type: "circle",
      color: theme.colors["negative"],
    },
    [answerStatus.MISSED]: {
      show: true,
      type: "underline",
      color: theme.colors["caution"],
    },
  }

  const solutionStyle = solutionStyles[status]

  return (
    <RoughNotation {...solutionStyle} {...restProps}>
      {children}
    </RoughNotation>
  )
}

NotationSolution.propTypes = {
  id: PropTypes.string.isRequired,
}

NotationSolution.defaultProps = {
  state: answerStatus.VALID_UNCHECKED,
}

export default NotationSolution
