import React, { useState, createContext, useContext } from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"

const GroupContext = createContext(null)

export const useNotationGroup = () => {
  return useContext(GroupContext)
}

const NotationGroup = (props) => {
  const { children, onNotationsUpdated, initialState, ...restProps } = props
  const [state, setState] = useState(initialState)

  const setNotationChanged = ({ id, active, ...restProps }) => {
    const newNotationState = {
      ...state[id],
      active,
      ...restProps,
    }
    const newState = {
      ...state,
      [id]: newNotationState,
    }
    setState(newState)
    if (onNotationsUpdated) {
      onNotationsUpdated(newState)
    }
  }

  return (
    <GroupContext.Provider
      value={{
        state,
        setNotationChanged,
        ...restProps,
      }}
    >
      {children}
    </GroupContext.Provider>
  )
}

NotationGroup.propTypes = {
  onNotationsUpdated: PropTypes.func,
  initialState: PropTypes.object,
}

NotationGroup.defaultProps = {
  initialState: {},
}

export default NotationGroup
