import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import TaskSidebar from "../../../../../components/task-sidebar"
import NotationGroup from "../../../../../components/notation-group"
import NotationSolution from "../../../../../components/notation-solution"
import SubstantiveAdjektiveNotation from "../../../../../content/kurse/haelfte-des-lebens/01-zwei-haelften/substantive-adjektive-notation"
import FeebackHeaderProgress from "../../../../../components/feedback-header-progress"
import Score from "../../../../../components/score"

const Page = () => {
  const { getStatsForTaskWithDict, answerStatusForDict } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  const task = {
    courseId: "haelfte-des-lebens",
    chapterId: "01-zwei-haelften",
    taskId: "substantive-und-adjektive",
  }
  const comparator = ({ answer, solution, status }) => {
    if (answer?.active === true && solution === true) {
      return status.VALID_CHECKED
    }
    if (answer?.active === true && solution !== true) {
      return status.INVALID
    }
    if (solution && !answer?.active) {
      return status.MISSED
    }
    return status.VALID_UNCHECKED
  }
  const answers = answerStatusForDict({
    ...task,
    comparator,
  })
  const statistics = getStatsForTaskWithDict({
    ...task,
    comparator,
  })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/haelfte-des-lebens/01-zwei-haelften/substantive-und-adjektive/zusammenfassung"
        />
      }
    >
      <Seo title="Substantive und Adjektive" />
      <Stack>
        <FeebackHeaderProgress
          variant="solution"
          correct={statistics.correct}
          total={statistics.correct + statistics.missed}
        />
        <TaskSidebar
          main={
            <NotationGroup initialState={answers}>
              <SubstantiveAdjektiveNotation notationType={NotationSolution} />
            </NotationGroup>
          }
          sidebar={<Score statistics={statistics} />}
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
